export const BaseUrl = "https://www.sankhyana.com/"
export const login = "https://www.sankhyana.com/login/login"


// Batch
export const createBatch = "https://www.sankhyana.com/createBatch"
export const getAllBatch = "https://www.sankhyana.com/getAllBatch"
export const getBatchBYID = "https://www.sankhyana.com/getBatchBYID/"
export const deleteBatch = "https://www.sankhyana.com/deleteBatch/"
export const updateBatch= "https://www.sankhyana.com/updateBatch/"

                                                                    

// Blog
export const createBlog = "https://www.sankhyana.com/createBlog"
export const getAllBlog="https://www.sankhyana.com/getAllBlog"
export const createBlogDetail= "https://www.sankhyana.com/createBlogDetail"
export const getAllBlogDetail= "https://www.sankhyana.com/getAllBlogDetail"
export const deleteBlogDetail= "https://www.sankhyana.com/deleteBlogDetail/"
export const getAllBlogDetailBYID= "https://www.sankhyana.com/getAllBlogDetailBYID/"

// career
export const createCareer = "https://www.sankhyana.com/createCareer"
export const getAllCareer= "https://www.sankhyana.com/getAllCareer"
export const deleteCareer= "https://www.sankhyana.com/deleteCareer/"
export const getCareerBYID= "https://www.sankhyana.com/getCareerBYID/"
export const updateCareer= "https://www.sankhyana.com/updateCareer/"

// Hiring
export const createHiring= "https://www.sankhyana.com/createHiring"
export const getAllHiring= "https://www.sankhyana.com/getAllHiring"
export const deleteHiring= "https://www.sankhyana.com/deleteHiring/"
export const updateHiring = "https://www.sankhyana.com/updateHiring/"

// offer
export const createOffer= "https://www.sankhyana.com/createOffer"
export const getAllOffer= "https://www.sankhyana.com/getOffer"
export const deleteOffer= "https://www.sankhyana.com/deleteOffer/"
export const updateOffer= "https://www.sankhyana.com/updateOffer/"

// Recent News
export const createRecent= "https://www.sankhyana.com/createRecent"
export const getRecent= "https://www.sankhyana.com/getAllRecent"
export const deleteRecent= "https://www.sankhyana.com/deleteRecent/"


// Training
export const getAllTraining= "https://www.sankhyana.com/getAllTraining"
export const deleteTraining= "https://www.sankhyana.com/deleteTraining/"
export const createTraining= "https://www.sankhyana.com/createTraining"
export const createTrainingDetail= "https://www.sankhyana.com/createTrainingDetail"
export const getAllTDetail= "https://www.sankhyana.com/getAllTDetail"



// mail databse
export const contactus = "https://www.sankhyana.com/contact/contactus"
export const connectnow= "https://www.sankhyana.com/connect/connectnow"
export const enrollnow = "https://www.sankhyana.com/Enroll/createenroll"
export const landingcreate = "https://www.sankhyana.com/api/form/register"

 // mail 

 export const Landing = "https://www.sankhyana.com/api/form/register"

 export const Enroll = "https://www.sankhyana.com/Enroll/Enroll"
export const contact = "https://www.sankhyana.com/contact/contact"
export const apply = "https://www.sankhyana.com/apply/applyby" 
export const connect = "https://www.sankhyana.com/connect/connect"

export const visit = "https://www.sankhyana.com/visit/"



export const createlanding = "https://www.sankhyana.com/createlanding"


















































// export const BaseUrl = "http://localhost:3000/"
// export const login = "http://localhost:3000/login/login"


// // Batch
// export const createBatch = "http://localhost:3000/createBatch"
// export const getAllBatch = "http://localhost:3000/getAllBatch"
// export const getBatchBYID = "http://localhost:3000/getBatchBYID/"
// export const deleteBatch = "http://localhost:3000/deleteBatch/"
// export const updateBatch= "http://localhost:3000/updateBatch/"

                                                                    

// // Blog
// export const createBlog = "http://localhost:3000/createBlog"
// export const getAllBlog="http://localhost:3000/getAllBlog"
// export const createBlogDetail= "http://localhost:3000/createBlogDetail"
// export const getAllBlogDetail= "http://localhost:3000/getAllBlogDetail"
// export const deleteBlogDetail= "http://localhost:3000/deleteBlogDetail/"
// export const getAllBlogDetailBYID= "http://localhost:3000/getAllBlogDetailBYID/"

// // career
// export const createCareer = "http://localhost:3000/createCareer"
// export const getAllCareer= "http://localhost:3000/getAllCareer"
// export const deleteCareer= "http://localhost:3000/deleteCareer/"
// export const getCareerBYID= "http://localhost:3000/getCareerBYID/"
// export const updateCareer= "http://localhost:3000/updateCareer/"

// // Hiring
// export const createHiring= "http://localhost:3000/createHiring"
// export const getAllHiring= "http://localhost:3000/getAllHiring"
// export const deleteHiring= "http://localhost:3000/deleteHiring/"
// export const updateHiring = "http://localhost:3000/updateHiring/"

// // offer
// export const createOffer= "http://localhost:3000/createOffer"
// export const getAllOffer= "http://localhost:3000/getOffer"
// export const deleteOffer= "http://localhost:3000/deleteOffer/"
// export const updateOffer= "http://localhost:3000/updateOffer/"

// // Recent News
// export const createRecent= "http://localhost:3000/createRecent"
// export const getRecent= "http://localhost:3000/getAllRecent"
// export const deleteRecent= "http://localhost:3000/deleteRecent/"


// // Training
// export const getAllTraining= "http://localhost:3000/getAllTraining"
// export const deleteTraining= "http://localhost:3000/deleteTraining/"
// export const createTraining= "http://localhost:3000/createTraining"
// export const createTrainingDetail= "http://localhost:3000/createTrainingDetail"
// export const getAllTDetail= "http://localhost:3000/getAllTDetail"



// // mail databse
// export const contactus = "http://localhost:3000/contact/contactus"
// export const connectnow= "http://localhost:3000/connect/connectnow"
// export const enrollnow = "http://localhost:3000/Enroll/createenroll"
// export const landingcreate = "http://localhost:3000/api/form/register"

//  // mail 

//  export const Landing = "http://localhost:3000/api/form/register"

//  export const Enroll = "http://localhost:3000/Enroll/Enroll"
// export const contact = "http://localhost:3000/contact/contact"
// export const apply = "http://localhost:3000/apply/applyby"
// export const connect = "http://localhost:3000/connect/connect"

// export const visit = "http://localhost:3000/visit/"



// export const createlanding = "http://localhost:3000/createlanding"







