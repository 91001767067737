// import React from "react";
// import BannerPng from "../../assets/hero.png";
// import { GrUserExpert } from "react-icons/gr";
// import { MdOutlineAccessTime } from "react-icons/md";
// import { FaBookReader } from "react-icons/fa";
// import { FadeUp } from "../Hero/Hero";
// import { motion } from "framer-motion";

// const Banner = () => {
//   return (
//     <section className="bg-[#f7f7f7]">
//       <div className="container py-14 md:py-24 grid grid-cols-1 md:grid-cols-2 gap-8 space-y-6 md:space-y-0">
//         {/* Banner Image */}
//         <div className="flex justify-center items-center">
//           <motion.img
//             initial={{ opacity: 0, x: -50 }}
//             whileInView={{ opacity: 1, x: 0 }}
//             viewport={{ once: true }}
//             transition={{ duration: 0.5, ease: "easeInOut" }}
//             src={BannerPng}
//             alt=""
//             className="w-[350px] md:max-w-[450px] object-cover drop-shadow"
//           />
//         </div>
//         {/* Banner Text */}
//         <div className="flex flex-col justify-center">
//           <div className="text-center md:text-left space-y-12">
//             <motion.h1
//               initial={{ opacity: 0, scale: 0.5 }}
//               whileInView={{ opacity: 1, scale: 1 }}
//               viewport={{ once: true }}
//               transition={{ duration: 0.5 }}
//               className="text-3xl md:text-4xl font-bold !leading-snug"
//             >
//               Africa’s <span className="text-blue-800">Biggest</span> Scholarship Provider
//             </motion.h1>
//             <div className="flex flex-col gap-6">
//               <motion.div
//                 variants={FadeUp(0.2)}
//                 initial="initial"
//                 whileInView={"animate"}
//                 viewport={{ once: true }}
//                 className="flex items-center gap-4 p-6 bg-[#f4f4f4] border-2 border-blue-900 rounded-2xl hover:bg-white duration-300 hover:shadow-2xl"
//               >
//                 <FaBookReader className="text-5xl" />
//                 <p className="text-base">Sankhyana offers scholarships ranging from <span className="font-bold ">50% to 90%</span> on industry-demand courses, including Data Science, Data Engineering, Full Stack Development, and many more</p>
//               </motion.div>
//               <motion.div
//                 variants={FadeUp(0.4)}
//                 initial="initial"
//                 whileInView={"animate"}
//                 viewport={{ once: true }}
//                 className="flex items-center gap-4 p-6 bg-[#f4f4f4] border-2 rounded-2xl border-blue-900 hover:bg-white duration-300 hover:shadow-2xl"
//               >
//                 <GrUserExpert className="text-5xl" />
//                 <p className="text-base">We are committed to making high-quality education accessible to everyone. Our scholarship program is designed to support learners by providing significant tuition discounts.</p>
//               </motion.div>
//               <motion.div
//                 variants={FadeUp(0.6)}
//                 initial="initial"
//                 whileInView={"animate"}
//                 viewport={{ once: true }}
//                 className="flex items-center gap-4 p-6 bg-[#f4f4f4] border-2 rounded-2xl border-blue-900 hover:bg-white duration-300 hover:shadow-2xl"
//               >
//                 <MdOutlineAccessTime className="text-5xl" />
//                 <p className="text-base">To receive between <span className="font-bold ">50% to 90%</span> off on your tuition fees, all you need to do is clear an interview. Once selected, your journey to mastering cutting-edge skills begins!</p>
//               </motion.div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Banner;










import React from "react";
import BannerPng from "../../assets/hero.png";
import { GrUserExpert } from "react-icons/gr";
import { MdOutlineAccessTime } from "react-icons/md";
import { FaBookReader } from "react-icons/fa";
import { FadeUp } from "../Hero/Hero";
import { motion } from "framer-motion";

const Banner = () => {
  return (
    <section className="bg-[#f7f7f7]">
      <div className="container pl-0 py-14 md:py-24 grid grid-cols-1 md:grid-cols-2 gap-8 space-y-6 md:space-y-0">
        {/* Banner Image - Hidden on small screens */}
        <div className="hidden md:flex justify-center items-center">
          <motion.img
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
            src={BannerPng}
            alt="Banner"
            className="w-[450px] md:max-w-[450px] object-cover drop-shadow"
          />
        </div>
        
        {/* Banner Text */}
        <div className="flex flex-col justify-center">
          <div className="text-center md:text-left space-y-12">
            <motion.h1
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              className="text-3xl md:text-4xl font-bold !leading-snug"
            >
Step into a world of <span className="text-blue-800">opportunity </span>and <span className="text-blue-800">innovation</span> with Sankhyana.

              {/* Africa’s <span className="text-blue-800">Biggest</span> Scholarship Provider */}
            </motion.h1>
            <div className="flex flex-col gap-6">
              <motion.div
                variants={FadeUp(0.2)}
                initial="initial"
                whileInView={"animate"}
                viewport={{ once: true }}
                className="flex items-center gap-4 p-6 bg-[#f4f4f4] border-2 border-blue-900 rounded-2xl hover:bg-white duration-300 hover:shadow-2xl"
              >
                {/* Icon hidden on mobile screens */}
                <FaBookReader className="text-5xl hidden md:block" />
                <p className="text-base">
                  Sankhyana offers scholarships ranging from{" "}
                  <span className="font-bold">50% to 80%</span> on industry-demand courses, including Data Science, Data Engineering, Full Stack Development, and many more
                </p>
              </motion.div>
              <motion.div
                variants={FadeUp(0.4)}
                initial="initial"
                whileInView={"animate"}
                viewport={{ once: true }}
                className="flex items-center gap-4 p-6 bg-[#f4f4f4] border-2 rounded-2xl border-blue-900 hover:bg-white duration-300 hover:shadow-2xl"
              >
                {/* Icon hidden on mobile screens */}
                <GrUserExpert className="text-5xl hidden md:block" />
                <p className="text-base">
                  We are committed to making high-quality education accessible to everyone. Our scholarship program is designed to support learners by providing significant tuition discounts.
                </p>
              </motion.div>
              <motion.div
                variants={FadeUp(0.6)}
                initial="initial"
                whileInView={"animate"}
                viewport={{ once: true }}
                className="flex items-center gap-4 p-6 bg-[#f4f4f4] border-2 rounded-2xl border-blue-900 hover:bg-white duration-300 hover:shadow-2xl"
              >
                {/* Icon hidden on mobile screens */}
                <MdOutlineAccessTime className="text-5xl hidden md:block" />
                <p className="text-base">
                  To receive between <span className="font-bold">50% to 80%</span> off on your tuition fees, all you need to do is clear a scholarship interview. Once selected, your journey to mastering cutting-edge skills begins!
                </p>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;